import { Link } from "react-router-dom";
import Modal from "./Modal";
import settings from "../../config";

import { ReactComponent as Close } from "../../img/icons/close.svg";

import { ReactComponent as DiscordCircleIcon } from "../../img/icons/discord-circle.svg";
import { ReactComponent as PhoneCircleIcon } from "../../img/icons/phone-circle.svg";
import { ReactComponent as TelegramCircleIcon } from "../../img/icons/telegram-circle.svg";
import { useEffect, useState } from "react";

const NeedHelpModal = ({ open, onClose }) => {
  const randomlyTeamMembers = (array) => {
    return array
      .map((item) => ({ ...item, sortKey: Math.random() }))
      .sort((a, b) => a.sortKey - b.sortKey)
      .map(({ sortKey, ...item }) => item);
  };
  const teamMembers = [
    {
      name: "Jack Tanner",
      role: "Chief Executive Officer",
      avatar: require("../../img/jack-tanner-avatar.png"),
      discord: "https://discordapp.com/users/574914463141068820",
      phone: "+31622165433",
    },
    {
      name: "Philip Patterson",
      role: "Pangea Fundraising Lead",
      avatar: require("../../img/phil-patterson-avatar.jpeg"),
      discord: "https://discordapp.com/users/1009886278898159626",
      telegram: "https://t.me/philjames8",
      phone: "+447828699027",
    },
    {
      name: "Chetana Bhardwaj",
      role: "Chief Operations Officer",
      avatar: require("../../img/chetana-bhardwaj-avatar.png"),
      discord: "https://discordapp.com/users/1078255075425783869",
      telegram: "https://t.me/Chetana_Bhardwaj",
      phone: "+31644176873",
    },
  ];

  const [randomizedTeamMembers, setRandomizedTeamMembers] = useState([]);

  useEffect(() => {
    setRandomizedTeamMembers(randomlyTeamMembers(teamMembers));
  }, []);
  return (
    <Modal isOpen={open} onClose={() => onClose(false)}>
      <Modal.Body header={false}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="fs-3">Need Help?</h3>
          <button className="modal-close" onClick={() => onClose(false)}>
            <Close />
          </button>
        </div>
        <div className="d-flex flex-column gap-4 support-users mt-4 mb-4">
          {randomizedTeamMembers.map((user, index) => (
            <div
              key={index}
              className="d-flex flex-row gap-md-3 gap-2 user align-items-center"
            >
              <img
                className="rounded-circle"
                src={user.avatar}
                width={80}
                height={80}
                alt={`Contact ${user.name}`}
              />
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center gap-2">
                  <h5>{user.name}</h5>
                  <div>
                    <a href={user.discord} target="_blank">
                      <DiscordCircleIcon
                        width="24"
                        height="24"
                        className="ms-2"
                      />
                    </a>
                    {user.telegram && (
                      <a href={user.telegram} target="_blank">
                        <TelegramCircleIcon
                          width="24"
                          height="24"
                          className="ms-2"
                        />
                      </a>
                    )}
                  </div>
                </div>
                <div className="mt-1">
                  <span className="badge badge-purple fw-normal text-dark">
                    {user.role}
                  </span>
                  <div className="d-flex align-items-center gap-1 mt-1">
                    <PhoneCircleIcon
                      width="26"
                      height="26"
                      className="text-muted"
                    />
                    <Link className="text-muted" to={`tel:${user.phone}`}>
                      {user.phone}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="support-links d-flex gap-3">
          <Link to={settings.config.helpUrl}>Send us a message</Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NeedHelpModal;
