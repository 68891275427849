import React from "react";

const SelectField = ({
  label,
  name,
  id,
  options,
  register,
  errors,
  defaultValue,
  onChange = null,
}) => {
  const { onChange: registerOnChange, ...restRegister } = register(name);

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
    registerOnChange(e);
  };

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        {...restRegister} // Spread the rest of the register attributes
        className={`form-select ${errors[name] ? "is-invalid" : ""}`}
        id={id}
        aria-label={`Default select ${label}`}
        defaultValue={defaultValue || ""}
        onChange={handleOnChange} // Custom combined onChange handler
      >
        <option value={""}>Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[name] && (
        <div className="invalid-feedback d-block">{errors[name].message}</div>
      )}
    </div>
  );
};

export default SelectField;
