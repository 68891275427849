import * as yup from "yup";

const FormSchema = yup
  .object({
    businessOrPerson: yup.string().required("Field is required!"),
    chamberOfCommerceNumber: yup.string().when("businessOrPerson", {
      is: "business",
      then: (schema) =>
        schema.required("Chamber of commerce number is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    registeredAddress: yup.string().when("businessOrPerson", {
      is: "business",
      then: (schema) => schema.required("Registered address is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    companyName: yup.string().when("businessOrPerson", {
      is: "business",
      then: (schema) => schema.required("Company name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    vatNumber: yup.string().when("businessOrPerson", {
      is: "business",
      then: (schema) => schema.required("Vat number is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    firstName: yup.string().when("businessOrPerson", {
      is: "person",
      then: (schema) => schema.required("First name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    lastName: yup.string().when("businessOrPerson", {
      is: "person",
      then: (schema) => schema.required("Last name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    email: yup.string().email("Invalid email").required("Email is required"),
    marketingEmailConsent: yup
      .boolean()
      .default(false)
      .required("Email Consent is required"),
    referralCode: yup.string(),
    accountName: yup
      .string()
      .required("United Citizens Wallet Account Name is required"),
    username: yup.string().default(""),
    docusealUrl: yup
      .string()
      .url("Invalid URL")
      .required("Docuseal URL is required"),
    paymentDate: yup
      .date()
      .default(() => new Date())
      .required("Payment Date is required"),
    amount: yup
      .number()
      .typeError("Amount must be a number")
      .required("Amount is required")
      .positive("Must be a positive number"),
    currency: yup
      .string()
      .oneOf(["ETH", "USD", "USDT", "EUR"], "Invalid Currency")
      .required("Currency is required"),
    paymentMethod: yup
      .string()
      .oneOf(["metamask", "SEPA", "other"], "Invalid payment method")
      .required("Payment method is required"),
    transactionHash: yup.string().when("paymentMethod", {
      is: "metamask",
      then: (schema) =>
        schema.required("Metamask transaction hash is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    paymentReference: yup.string().when("paymentMethod", {
      is: (method) => method !== "metamask",
      then: (schema) => schema.required("Payment reference is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    leosAmount: yup
      .number()
      .typeError("LEOS amount must be a number")
      .required("LEOS amount is required")
      .positive("Must be a positive number"),
    leosIssued: yup.boolean().default(false).required("Field is required"),
    transactionId: yup.string().when("leosIssued", {
      is: "Yes",
      then: (schema) => schema.required("Transaction ID is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    seedRound: yup.string().when("leosIssued", {
      is: "No",
      then: (schema) => schema.required("Seed round is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    treasury: yup.string().when("leosIssued", {
      is: "No",
      then: (schema) => schema.required("Treasury is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  })
  .required();

export default FormSchema;
